import NavBar from "../components/navigation/NavBar"
import React from "react"
import Link from "next/link"
import Footer from "../components/footer/Footer"
import MbpHead from "../components/head/MbpHead"
import Typography from "../components/reusable/Typography/Typography"
import {serverSideTranslations} from "next-i18next/serverSideTranslations"
import nextI18NextConfig from "../next-i18next.config"
import {getPageData} from "../pageData"
import HomeCatBoxes from "../components/home/homeCatBoxes/HomeCatBoxes"
import FindPerfectBike from "../components/home/FindPerfectBike/FindPerfectBike"

const FourOFour = () => {
	const link = "https://api.whatsapp.com/send?phone=41435051318"
	return (
		<div>
			<div>
				<MbpHead>
					<title>
                        Wir Sagen Danke
					</title>
					<meta name="robots"
						content="noindex, nofollow" />
				</MbpHead>
				<NavBar/>
				<main>
					<div className={"container"}>
						<div className={"row justify-content-center"}>
							<div className={"col-11 container text-left "}>
								<Typography style={{
									marginBottom: "3rem", paddingTop: "5rem"}}
								variant="heading2"
								semanticTag="h1"
								className="text-md-center">
									Liebe MySofaPlan-Community
								</Typography>
								<Typography
									variant="heading3"
									semanticTag="h3"
									className="mb-1">
									Danke für deinen Besuch!
								</Typography>
								<Typography
									variant="bodyLg"
									semanticTag="p"
									className="mb-5">
									Wir haben unsere Türen dauerhaft geschlossen, um uns voll und ganz auf
                                    unser Kerngeschäft zu fokussieren: <Typography semanticTag="span"
										variant="bodyLgBold"> E-Bikes und Velos bei {" "}
										<Link href={"https://www.mybikeplan.ch"}>
											<a>
												MyBikePlan.ch.
											</a>
										</Link>
										{" "}
									</Typography>
                                    Unsere Mission?
									{" "}
									<Typography semanticTag="span"
										variant="bodyLgBold">
                                     E-Mobilität für alle erschwinglich machen</Typography> – denn das ist es,
                                    was wir am Besten können!⚡
								</Typography>
								<Typography
									variant="heading3"
									semanticTag="h3"
									className="mb-1">
                                    Du hast einen offenen Vertrag mit MySofaPlan?
								</Typography>
								<Typography
									variant="bodyLg"
									semanticTag="p"
									className="mb-5">
									Wir schätzen dich sehr! Keine Sorge, alles bleibt beim Alten.
                                    Dein Vertrag wird wie gewohnt fortlaufen und es wird sich nichts ändern.
								</Typography>
								<Typography
									variant="heading3"
									semanticTag="h3"
									className="mb-1">
                                    Du wartest zurzeit noch auf dein Möbel?
								</Typography>
								<Typography
									variant="bodyLg"
									semanticTag="p"
									className="mb-5">
									Deiner Vorfreude steht nichts im Weg! Dein Möbel wird wie besprochen ausgeliefert.
                                    Bei deinem Vertrag wird es auch keine Anpassungen geben.
                                    Sehr gerne sind wird jederzeit für dich via
									{" "}
									<Typography semanticTag="span"
										variant="bodyLgBold">
										<Link href={link}>
											<a>
                                            WhatsApp
											</a>
										</Link>
									</Typography>
									{" "}
                                      erreichbar.👩🏽‍💻
								</Typography>
								<Typography
									variant="heading3"
									semanticTag="h3"
									className="mb-1">
                                   💙Danke, dass du Teil von MySofaPlan warst!
								</Typography>
								<Typography
									variant="bodyLg"
									semanticTag="p"
									className="mb-5">
									Besuche uns gerne auf {" "}
									<Typography semanticTag="span"
										variant="bodyLgBold">
										<Link href={"https://www.mybikeplan.ch"}>
											<a>
												MyBikePlan.ch.
											</a>
										</Link>
									</Typography>
									{" "} und verfolge unsere weitere Reise.
                                    Wir freuen uns auf ein Wiedersehen auf der Strasse!🚴🏻‍♂️✨
								</Typography>
							</div>
							<HomeCatBoxes is404Page
								hideTopModels />
							<div className="col-12 py-4 mb-3 mt-3">
								<FindPerfectBike />
							</div>
						</div>
					</div>
				</main>
				<Footer/>
			</div>
		</div>
	)
}

export async function getStaticProps({locale}) {
	return {
		props: {
			...(await getPageData(locale)),
			...(await serverSideTranslations(locale, ["common", "URL", "search", "filters"], nextI18NextConfig))
		}
	}
}

export default FourOFour
